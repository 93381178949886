import React from "react"
import { Link } from "gatsby"

function Related(props) {

  return (
    <div className="linked-posts">
      <p>Related posts:</p>
      <ul>
        {props.related.map((relatedPost, index) => {
          return (<li key={index}>
              <Link to={`/${relatedPost.slug}/`}>{relatedPost.label}</Link> - {relatedPost.description}.
          </li>)
        })}
      </ul>
    </div>
  )
}

export default Related