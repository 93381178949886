import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Share from "../components/share"
import Related from "../components/related"
import { OutboundLink } from "gatsby-plugin-google-analytics"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const tags = post.frontmatter.tags? post.frontmatter.tags.join(', '): '';
    const thumbnail = post.frontmatter.thumbnail;
    const thumbnailAlt = post.frontmatter.thumbnailAlt;
    const cardType = post.frontmatter.cardType;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          thumbnail={thumbnail}
          thumbnailAlt={thumbnailAlt}
          cardType={cardType}
        />
        <h1>{post.frontmatter.title}</h1>
        <p className="meta">{post.frontmatter.date} {post.frontmatter.updatedDate && `(updated ${post.frontmatter.updatedDate})`} {tags? `| ${tags}` : ''}</p>
        <div className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
        
        <Share title={post.frontmatter.title} slug={post.fields.slug} />
        
        {post.frontmatter.related && <Related related={post.frontmatter.related} />}
        <p className="email-signup">If you <span className="email-highlight">liked this post</span>, you can <span className="email-highlight">sign up</span> to get {post.frontmatter.type === "book"? "more book notes" : "more essays"} <OutboundLink href="https://www.getrevue.co/profile/stevanpopo" target="_blank">via my email</OutboundLink>.</p>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "D MMM, YYYY")
        updatedDate(formatString: "D MMM, YYYY")
        description
        tags
        thumbnail {
          childImageSharp {
            sizes(maxWidth: 600) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        thumbnailAlt
        cardType
        type
        related {
          label
          slug
          description
        }
      }
    }
  }
`
