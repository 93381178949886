import React from "react"
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

function Share(props) {

  return (
    <div className="share">
      <span>Share: </span>
      <OutboundLink className="share-link twitter" href={`https://twitter.com/intent/tweet/?text=${props.title}&url=https://stevanpopovic.com${props.slug}&via=stevanpopo`} target="_blank">
        <FaTwitter title="Twitter icon" />
        Twitter
      </OutboundLink>
      <OutboundLink className="share-link linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://stevanpopovic.com${props.slug}&title=${props.title}&source=https://stevanpopovic.com&summary=${props.title}`} target="_blank">
        <FaLinkedin title="LinkedIn icon"/>
        LinkedIn
      </OutboundLink>
      <OutboundLink className="share-link email" href={`mailto:friend@domain.com?subject=${props.title}`}>
        <FaEnvelope title="LinkedIn icon"/>
        Email
      </OutboundLink>
    </div>
  )
}

export default Share
